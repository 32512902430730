<template>
  <div class="text-reply-list-item">
    <span class="number">{{ index + 1 }}. 文本：</span>
    <div class="content">
      {{ item.content }}
    </div>
    <div class="edit-btns">
      <i class="el-icon-edit" @click="edit" />
      <i class="el-icon-delete" @click="remove" />
    </div>
  </div>
</template>

<script>
export default {
  name: "text-reply-list-item",
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    edit() {
      this.$emit("edit", this.item);
    },
    remove() {
      this.$emit("remove", this.item);
    },
  },
};
</script>

<style lang="less">
.text-reply-list-item {
  display: flex;
  border-bottom: 1px solid #000000;
  margin-bottom: 1rem;
  width: 80%;

  i {
    font-size: 1.5rem;
  }
}
</style>